import React, { useState ,useEffect} from "react";
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import Pricing from "../components/pricing/version2"
import {Row, Col, Image, Carousel} from "react-bootstrap"
import HomeImage1 from "../images/foodslogo.svg"
import HomeImage2 from "../images/h2.svg"
import HomeImage3 from "../images/h3.svg"
import HomeImage4 from "../images/h4.svg"
import HomeImageShop from "../images/icon-shop.png"
import HomeImageAvatar from "../images/user.png"
import googlePic from "../images/GooglePlay.png"
import appstorePic from "../images/AppStore.png"
import scanApple from "../images/scan_apple.png"
import  scanAndroid from "../images/scan_android.png"
import { Helmet } from 'react-helmet';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby"
import ReminderModal from "../components/freeTrial/reminderModal"
import {getReminder, isSpecial as isSpecialFunc} from "../utils/functions"
import HomeCarousel from "../components/home/carousel"
const IndexPage = (props) => {
  const {t} = useTranslation();
  const [reminderVisibility,setReminderVisibility] = useState(false)
  const {products} = props.data
  const handlerDownloadApp = (isAndroid) => {
    const downloadUrl = isAndroid ? 'https://play.google.com/store/apps/details?id=com.mykitcheninspector.kleankitchen': 'https://apps.apple.com/app/id1582955246';
    window.open(downloadUrl,"_blank");
  }
  const isSSR = typeof window === "undefined"
  let isSpecial = false
  if(!isSSR){
    isSpecial = isSpecialFunc()
  }
  const onHide = () =>{
    setReminderVisibility(false)
    localStorage.setItem("reminder",false)
  }
  useEffect(() => {
    const reminder = getReminder()
    if(reminder=="1"){
      setReminderVisibility(true)
    }
  }, []);
  return(
    <>
      <HeardTopWrapper title={t('homeBannerDes')} isHome />
      <div className="home-container">
        {/* <h2 className="home-title">{t('hearMore')}</h2> */}
        {/* <HomeCarousel/> */}
        <Layout>
          <Helmet>
            <title>{t('myKitchenInspector')}｜{t('Home')}</title>
            <meta
              name='description'
              content={ t('Home')}
            />
          </Helmet>
        <PageContainer>
          <>
            <ReminderModal reminderVisibility={reminderVisibility} onHide={onHide}/>
            <div className="home-line"></div>
            {!isSpecial && <Pricing data={products} />}
            <div className="home-line"></div>
            <h2 className="home-title">{t('DownloadOurApp')}</h2>
            <div className="get-app">
              <div className="download-item" onClick={()=>handlerDownloadApp(true)}>
                <div className="download-item-img">
                  <Image src={googlePic}/>
                </div>
                <div className="download-item-des">
                  <span>{t('getFrom')}</span>
                  <p>{t('googlePlay')}</p>
                </div>
              </div>
              <div className="download-item" onClick={()=>handlerDownloadApp(false)}>
                <div className="download-item-img">
                  <Image src={appstorePic}/>
                </div>
                <div className="download-item-des">
                  <span>{t('getFrom')}</span>
                  <p>{t('appStore')}</p>
                </div>
              </div>
            </div>
            <div className="get-app get-app-scan">
              <div className="download-item">
                <Image src={scanAndroid}/>
              </div>
              <div className="download-item">
                <Image src={scanApple}/>
              </div>

            </div>
          </>
        </PageContainer>
      </Layout>
      </div>
    </>
  )
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    products: allWpSimpleProduct {
      edges {
       node {
         id
         # type
         name
         expiresIn
         price
         link
         slug
         image {
           id
           altText
           sourceUrl
         }
       attributes {
       nodes{ 
         name
         options
         }
       }
       }
     }
    }
  }

`;


