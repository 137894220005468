import React from "react";
import {Modal,Image} from "react-bootstrap"
import {Link} from "gatsby"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import notification from "../../images/notification.png"
const ReminderModal = (props) => {
  const {t} = useTranslation();
  const {reminderVisibility,onHide} = props;
  return (
    <div>
      <Modal show={reminderVisibility} onHide={onHide} className="formWrap reminderModal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Image src={notification} alt="" width="60"/>
          <h2>{t('reminder')}</h2>
          <p>
            {t('remiderDes')} <Link to={`${process.env.BACKOFFICE_API_URL}`}>{t('administration_portal')} </Link>
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ReminderModal;

